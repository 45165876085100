body {
  min-width: 320px;
  margin: 0;
  font-family: Inter, sans-serif;
}

a {
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: inherit;
  transition: color .2s;
}

@media (hover: hover) {
  a:hover, .hoverFooterItem:hover {
    color: #e1bb0b !important;
  }

  .hoverFooterItem:hover svg path {
    fill: #e1bb0b;
  }
}

button {
  font-family: Inter, sans-serif;
  transition: background-color .2s;
}

form {
  margin: 0;
}

input {
  outline-offset: -3px;
}

input::placeholder {
  color: #555559;
}

button {
  outline-offset: 4px;
}

h1 {
  color: #1a1b21;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

@media (min-width: 768px) {
  h1 {
    font-size: 36px;
  }
}

h2 {
  color: #1a1b21;
  word-break: break-word;
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

@media (min-width: 768px) {
  h2 {
    margin: 0 0 30px;
    font-size: 48px;
  }
}

h3 {
  color: #1a1b21;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
}

@media (min-width: 768px) {
  h3 {
    font-size: 24px;
  }
}

.whiteText {
  color: #fff;
}

.blueText {
  color: #2684ff;
}

section {
  width: auto;
  padding: 40px 25px;
}

@media (min-width: 768px) {
  section {
    max-width: 1300px;
    padding: 60px 40px;
  }
}

@media (min-width: 960px) {
  section {
    padding: 80px 40px;
  }
}

@media (min-width: 1380px) {
  section {
    margin: auto;
    padding: 80px 0;
  }
}

.firstSection {
  padding-top: unset;
  padding-bottom: unset;
  box-sizing: border-box;
  width: 100%;
}

@media (max-width: 499px) {
  .firstSection {
    overflow: hidden;
  }
}

.logo {
  width: 140px;
  height: 36px;
  position: absolute;
  top: 30px;
}

@media (min-width: 768px) {
  .logo {
    width: 188px;
    height: 49px;
  }
}

.bannerContainer {
  flex-direction: column-reverse;
  gap: 30px;
  margin-bottom: 40px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .bannerContainer {
    flex-direction: row;
    gap: 40px;
    margin-bottom: 100px;
  }
}

@media (min-width: 1500px) {
  .bannerContainer {
    margin-bottom: 312px;
  }
}

.bannerContainer .titleContainer {
  width: 100%;
}

@media (min-width: 1024px) {
  .bannerContainer .titleContainer {
    width: calc(40% - 30px);
  }
}

@media (hover: hover) {
  .bannerContainer .titleContainer button:hover {
    background: #00f !important;
  }
}

.bannerContainer .titleContainer h1 {
  word-break: break-word;
  margin-top: 0;
  margin-bottom: 25px;
}

@media (min-width: 1024px) {
  .bannerContainer .titleContainer h1 {
    margin-top: 185px;
    margin-bottom: 54px;
  }
}

@media (min-width: 1240px) {
  .bannerContainer .titleContainer h1 {
    margin-top: 212px;
  }
}

.banner {
  width: 116%;
  object-fit: contain;
  margin-top: 80px;
  position: relative;
  left: -13%;
}

@media (min-width: 500px) {
  .banner {
    width: 100%;
    left: unset;
    margin-top: 88px;
  }
}

@media (min-width: 1024px) {
  .banner {
    width: 70%;
    margin-top: 130px;
  }
}

@media (min-width: 1250px) {
  .banner {
    margin-top: 88px;
  }
}

@media (min-width: 1500px) {
  .banner {
    position: absolute;
    right: -7%;
  }
}

.banner source, .banner img {
  width: 100%;
}

.demoButton {
  height: 40px;
  width: 168px;
  color: #fff;
  cursor: pointer;
  background: #0049ff;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

@media (min-width: 768px) {
  .demoButton {
    height: 56px;
    width: 198px;
    font-size: 18px;
  }
}

.scatButton {
  height: 40px;
  width: 100%;
  color: #fff;
  background: #2684ff;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 83%;
  display: flex;
}

.scatButton span {
  display: block;
}

@media (min-width: 768px) {
  .scatButton {
    height: 80px;
    gap: 28px;
    margin-top: 60px;
    font-size: 24px;
  }
}

@media (max-width: 759px) {
  .scatButton .btnIcon {
    width: 30px;
  }
}

.blackBlock {
  background: #1a1b21;
  border-radius: 44px;
}

@media (min-width: 768px) {
  .blackBlock {
    border-radius: 70px;
  }
}

.blackBlock .platformBlock {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

@media (min-width: 1201px) {
  .blackBlock .platformBlock {
    grid-template-columns: 1fr 1fr;
  }
}

.blackBlock .platformElem {
  background: #24252b;
  border-radius: 20px;
  padding: 30px 40px 30px 15px;
}

@media (min-width: 960px) {
  .blackBlock .platformElem {
    padding: 50px 73px 50px 30px;
  }
}

.blackBlock .platformElem .titleLine {
  align-items: center;
  gap: 25px;
  display: flex;
}

.blackBlock .platformElem .titleLine .icon {
  width: 29px;
  height: 29px;
}

@media (min-width: 768px) {
  .blackBlock .platformElem .titleLine .icon {
    width: 48px;
    height: 48px;
  }
}

.blackBlock .platformElem .platformElemDesc {
  color: #a6a6a6;
  text-align: left;
  margin-top: 22px;
  font-size: 14px;
  line-height: 130%;
}

@media (min-width: 768px) {
  .blackBlock .platformElem .platformElemDesc {
    margin: 32px 0 0 74px;
    font-size: 18px;
    line-height: 120%;
  }
}

.advantagesBlock {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

@media (min-width: 1200px) {
  .advantagesBlock {
    grid-template-columns: 1fr 1fr;
  }
}

.advantagesBlock .advantagesElem {
  background: #f1f1f1;
  border-radius: 16px;
  padding: 24px 16px;
}

@media (min-width: 768px) {
  .advantagesBlock .advantagesElem {
    border-radius: 30px;
    padding: 50px;
  }
}

.advantagesBlock .advantagesElem .advantagesElemTitle {
  color: #0049ff;
}

@media (max-width: 767px) {
  .advantagesBlock .advantagesElem .advantagesElemTitle {
    max-width: 420px;
    font-size: 16px;
  }
}

.advantagesBlock .advantagesElem .advantagesElemDesc {
  color: #232323;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 12px;
  line-height: 120%;
}

@media (min-width: 768px) {
  .advantagesBlock .advantagesElem .advantagesElemDesc {
    margin-top: 30px;
    font-size: 18px;
  }
}

.advantagesBlock .advantagesElem .advantagesElemDesc li {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .advantagesBlock .advantagesElem .advantagesElemDesc li {
    margin-top: 15px;
  }
}

.lifeCycleBlock {
  padding-bottom: unset;
}

.lifeCycleBlock .lifeCycleBlockTitle {
  max-width: 300px;
}

@media (min-width: 768px) {
  .lifeCycleBlock .lifeCycleBlockTitle {
    max-width: 700px;
  }
}

.greyBlock {
  background: #f1f1f1;
  border-radius: 44px;
  margin: auto;
  padding: 40px 20px;
}

@media (min-width: 768px) {
  .greyBlock {
    border-radius: 70px;
    padding: 60px 40px 80px;
  }
}

@media (min-width: 960px) {
  .greyBlock {
    padding: 60px 40px 80px;
  }
}

@media (min-width: 1600px) {
  .greyBlock {
    padding: 60px 125px 80px;
  }
}

@media (min-width: 1440px) {
  .greyBlock {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.greyBlock .lifeCycleContent {
  padding: unset;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  display: flex;
}

@media (min-width: 1380px) {
  .greyBlock .lifeCycleContent {
    gap: 20px;
  }
}

@media (min-width: 1440px) {
  .greyBlock .lifeCycleContent {
    width: 1300px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleTitle {
  text-align: center;
  margin-bottom: 20px;
}

.greyBlock .lifeCycleContent .lifeCycleElem {
  width: calc(100% - 40px);
  height: auto;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

@media (min-width: 380px) {
  .greyBlock .lifeCycleContent .lifeCycleElem {
    width: 300px;
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .greyBlock .lifeCycleContent .lifeCycleElem {
    width: 360px;
    padding: 30px;
  }
}

@media (min-width: 960px) {
  .greyBlock .lifeCycleContent .lifeCycleElem {
    height: 385px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem {
  border-radius: 10px;
  padding: 20px 6px;
}

@media (min-width: 768px) {
  .greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem {
    padding: 25px 6px 22px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem .lifeCycleSubTitle {
  text-align: center;
  color: #1a1b21;
  margin-top: unset;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

@media (min-width: 768px) {
  .greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem .lifeCycleSubTitle {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem .icons {
  margin: auto;
  display: flex;
}

@media (max-width: 767px) {
  .greyBlock .lifeCycleContent .lifeCycleElem .lifeCycleItem .icons {
    width: 250px;
  }
}

@media (max-width: 1379px) {
  .greyBlock .lifeCycleContent .thirdLifeCycleElem {
    height: auto !important;
  }
}

.greyBlock .lifeCycleContent .arrow {
  margin: 9px 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .greyBlock .lifeCycleContent .arrow {
    height: 24px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleSmallElem {
  border-radius: 20px;
  justify-content: space-around;
  padding: 12px 6px;
  display: flex;
}

@media (min-width: 768px) {
  .greyBlock .lifeCycleContent .lifeCycleSmallElem {
    padding: 20px 10px;
  }
}

.greyBlock .lifeCycleContent .lifeCycleSmallElem .lifeCycleSmallElemText {
  color: #1a1b21;
  margin-top: unset;
  margin-bottom: unset;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
}

@media (min-width: 768px) {
  .greyBlock .lifeCycleContent .lifeCycleSmallElem .lifeCycleSmallElemText {
    font-size: 16px;
  }
}

.lilac {
  background-color: #7da2ff80;
}

.green {
  background-color: #b1f12680;
}

.orange {
  background-color: #ffea92;
}

.footerBlock {
  position: relative;
}

.footerBlock .formBlock {
  background: #2684ff;
  border-radius: 44px;
}

@media (min-width: 768px) {
  .footerBlock .formBlock {
    border-radius: 70px;
  }
}

.footerBlock .formBlock .formContent {
  overflow: hidden;
}

@media (min-width: 960px) {
  .footerBlock .formBlock .formContent {
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent {
    overflow: unset;
    justify-content: space-between;
    display: flex;
  }
}

.footerBlock .formBlock .formContent .successResponse {
  color: #fff;
  width: 102%;
  height: 105%;
  background: #2684ff;
  align-items: center;
  margin: 0;
  padding: 5px 0 0 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  display: none;
  position: absolute;
  top: -5px;
  left: -5px;
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .successResponse {
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .successResponse {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .footerBlock .formBlock .formContent .successResponse {
    justify-content: center;
  }
}

.footerBlock .formBlock .formContent .failedResponse {
  color: #e1bb0b;
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  display: none;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .failedResponse {
    text-align: right;
  }
}

.footerBlock .formBlock .formContent .formDescription {
  width: 100%;
}

@media (min-width: 960px) {
  .footerBlock .formBlock .formContent .formDescription {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .formDescription {
    width: 35%;
  }
}

.footerBlock .formBlock .formContent .formDescription .formTitle {
  color: #1a1b21;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formDescription .formTitle {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .formDescription .formTitle {
    margin-top: 10px;
    margin-bottom: 32px;
  }
}

.footerBlock .formBlock .formContent .formDescription .formSubTitle {
  color: #1d1d44;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formDescription .formSubTitle {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .formDescription .formSubTitle {
    margin-bottom: unset;
  }

  .footerBlock .formBlock .formContent .formDescription .formSubTitle span {
    width: 375px;
    display: block;
  }
}

.footerBlock .formBlock .formContent .formInputs {
  width: 100%;
  position: relative;
}

@media (min-width: 960px) {
  .footerBlock .formBlock .formContent .formInputs {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .formInputs {
    width: 58%;
  }
}

.footerBlock .formBlock .formContent .formInputs .input {
  height: 47px;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  padding: 12px 10px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 120%;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formInputs .input {
    height: 57px;
  }
}

.footerBlock .formBlock .formContent .formInputs .smallInput {
  width: 100%;
}

.footerBlock .formBlock .formContent .formInputs .bigInput {
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formInputs .bigInput {
    margin-bottom: 20px;
  }
}

.footerBlock .formBlock .formContent .formInputs .doubleInputs {
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formInputs .doubleInputs {
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  }

  .footerBlock .formBlock .formContent .formInputs .btnContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media (hover: hover) {
  .footerBlock .formBlock .formContent .formInputs .btnContainer button:hover {
    background: #0d0d10 !important;
  }
}

.footerBlock .formBlock .formContent .formInputs .btnContainer .btnText {
  color: #24252b;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 120%;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formInputs .btnContainer .btnText {
    margin-bottom: unset;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .footerBlock .formBlock .formContent .formInputs .btnContainer .btnText {
    width: 390px;
  }
}

@media (hover: hover) {
  .footerBlock .formBlock .formContent .formInputs .btnContainer .btnText a:hover {
    border-bottom: 1px solid #fff;
    color: #fff !important;
  }
}

.footerBlock .formBlock .formContent .formInputs .btnContainer .btnText .underlineText {
  cursor: pointer;
  border-bottom: 1px solid #24252b;
  transition: color .2s, border-bottom-color .2s;
}

.footerBlock .formBlock .formContent .formInputs .btnContainer .blackBtn {
  width: 100%;
  background-color: #24252b;
  padding: 0 25px;
}

@media (min-width: 768px) {
  .footerBlock .formBlock .formContent .formInputs .btnContainer .blackBtn {
    min-width: 215px;
    width: unset;
    height: 62px;
  }
}

.footerBlock .blackBg {
  width: 100%;
  height: 300px;
  z-index: -1;
  background-color: #24252b;
  position: absolute;
  top: 66%;
}

.footer {
  background-color: #24252b;
}

.footer .footerContent {
  justify-content: space-between;
  gap: 25px;
  padding-top: 25px;
  padding-bottom: 65px;
  display: flex;
  position: relative;
}

@media (max-width: 767px) {
  .footer .footerContent {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .footer .footerContent {
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 90px;
  }
}

@media (min-width: 960px) {
  .footer .footerContent {
    padding-top: 81px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .footer .footerContent {
    padding-bottom: 153px;
  }
}

.footer .footerContent .menu button {
  background-color: unset;
  border: unset;
  margin: unset;
  padding: unset;
}

.footer .footerContent .documents {
  font-size: 12px;
  line-height: 120%;
}

@media (min-width: 1200px) {
  .footer .footerContent .documents {
    font-size: 14px;
  }
}

.footer .footerContent .documents a {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  display: block;
}

@media (max-width: 767px) {
  .footer .footerContent .documents a {
    margin-bottom: 10px;
  }
}

.footer .footerContent .documents a:not(:last-child) {
  margin-bottom: 10px;
}

.footer .footerContent .footerText .whiteLogo {
  width: 110px;
  margin-bottom: 18px;
}

@media (min-width: 1200px) {
  .footer .footerContent .footerText .whiteLogo {
    width: 143px;
    height: 38px;
    margin-bottom: 23px;
  }
}

.footer .footerContent .footerSubTitle {
  color: #9c9d9f;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 120%;
}

@media (min-width: 768px) {
  .footer .footerContent .footerSubTitle {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .footer .footerContent .footerSubTitle {
    font-size: 20px;
  }
}

.footer .footerContent .footerItem {
  color: #fff;
  cursor: pointer;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  line-height: 120%;
  transition: color .2s;
  display: block;
  margin-bottom: 12px !important;
}

@media (min-width: 768px) {
  .footer .footerContent .footerItem {
    margin-bottom: 14px !important;
  }
}

@media (min-width: 1200px) {
  .footer .footerContent .footerItem {
    font-size: 16px;
  }
}

.footer .footerContent .footerItem:last-child {
  margin-bottom: 0;
}

.footer .footerContent .footerItem svg path {
  transition: fill .2s;
}

.footer .footerContent .footerItem .footerIcon {
  width: 16px;
  height: 16px;
}

@media (min-width: 1200px) {
  .footer .footerContent .footerItem .footerIcon {
    width: 24px;
    height: 24px;
  }
}

.footer .footerContent .flex {
  display: flex;
}

.footer .footerContent .price {
  box-sizing: border-box;
  color: #fff;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 380px;
  border: 1px solid #9c9d9f;
  border-radius: 12px;
  padding: 10px 8px 10px 12px;
  font-size: 12px;
  line-height: 135%;
}

@media (min-width: 768px) {
  .footer .footerContent .price {
    max-width: 130px;
    border-radius: 30px;
    padding: 15px 8px 15px 15px;
  }
}

@media (min-width: 960px) {
  .footer .footerContent .price {
    max-width: 170px;
  }
}

@media (min-width: 1200px) {
  .footer .footerContent .price {
    max-width: unset;
    width: 374px;
    padding: 20px;
    font-size: 16px;
    line-height: 120%;
  }
}

.footer .footerContent .price a {
  display: inline;
}

.footer .footerContent .copyright {
  display: flex;
  position: absolute;
  bottom: 25px;
}

@media (min-width: 768px) {
  .footer .footerContent .copyright {
    bottom: 36px;
  }
}

@media (min-width: 960px) {
  .footer .footerContent .copyright {
    bottom: 49px;
  }
}

.footer .footerContent .copyright div {
  color: #9c9d9f;
  align-items: center;
  font-size: 12px;
  display: flex;
}

@media (min-width: 1200px) {
  .footer .footerContent .copyright div {
    font-size: 14px;
  }
}

.footer .footerContent .copyright div span {
  margin: 0 9px;
}

@media (min-width: 768px) {
  .lifeCycle {
    max-width: 134px;
    display: block;
  }

  .descParam {
    max-width: 220px;
    display: block;
  }
}

.visibleResponse {
  display: flex !important;
}

.visibleResponse.failedResponse {
  display: block !important;
}

/*# sourceMappingURL=index.ad188cfb.css.map */
