body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  min-width: 320px;
}

a {
  text-decoration: unset;
  color: inherit;
  transition: color 200ms ease;
}

@media (hover: hover) {
  a:hover {
    color: #e1bb0b !important;
  }

  .hoverFooterItem:hover {
    color: #e1bb0b !important;

    svg {
      path {
        fill: #e1bb0b;
      }
    }
  }
}

button {
  font-family: 'Inter', sans-serif;
  transition: background-color 200ms ease;
}

form {
  margin: 0;
}

input {
  outline-offset: -3px;

  &::placeholder {
    color: #555559;
  }
}

button {
  outline-offset: 4px;
}

h1 {
  color: #1A1B21;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;

  @media (min-width: 768px) {
    font-size: 36px;
  }
}

h2 {
  color: #1A1B21;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin: 0 0 15px 0;
  word-break: break-word;

  @media (min-width: 768px) {
    font-size: 48px;
    margin: 0 0 30px 0;
  }
}

h3 {
  color: #1A1B21;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.whiteText {
  color: #FFFFFF;
}

.blueText {
  color: #2684FF;
}

section {
  width: auto;
  padding: 40px 25px;

  @media (min-width: 768px) {
    max-width: 1300px;
    padding: 60px 40px;
  }

  @media (min-width: 960px) {
    padding: 80px 40px;
  }

  @media (min-width: 1380px) {
    padding: 80px 0;
    margin: auto;
  }
}

.firstSection {
  padding-top: unset;
  padding-bottom: unset;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 499px) {
    overflow: hidden;
  }
}

.logo {
  position: absolute;
  top: 30px;
  width: 140px;
  height: 36px;

  @media (min-width: 768px) {
    width: 188px;
    height: 49px;
  }
}

.bannerContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  margin-bottom: 40px;
  position: relative;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 40px;
    margin-bottom: 100px;
  }

  @media (min-width: 1500px) {
    margin-bottom: 312px;
  }

  .titleContainer {
    width: 100%;

    @media (min-width: 1024px) {
      width: calc(40% - 30px);
    }

    @media (hover: hover) {
      button:hover {
        background: #0000ff !important;
      }
    }

    & h1 {
      word-break: break-word;
      margin-top: 0;
      margin-bottom: 25px;

      @media (min-width: 1024px) {
        margin-top: 185px;
        margin-bottom: 54px;
      }

      @media (min-width: 1240px) {
        margin-top: 212px;
      }
    }
  }
}

.banner {
  width: 116%;
  object-fit: contain;
  margin-top: 80px;
  position: relative;
  left: -13%;

  @media (min-width: 500px) {
    width: 100%;
    margin-top: 88px;
    left: unset;
  }

  @media (min-width: 1024px) {
    margin-top: 130px;
    width: 70%;
  }

  @media (min-width: 1250px) {
    margin-top: 88px;
  }

  @media (min-width: 1500px) {
    position: absolute;
    right: -7%;
  }

  source,
  img {
    width: 100%;
  }
}

.demoButton {
  height: 40px;
  width: 168px;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  color: #FFFFFF;
  background: #0049FF;
  border-radius: 30px;
  border: none;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 56px;
    width: 198px;
    font-size: 18px;
  }
}

.scatButton {
  margin-top: 30px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 83%;
  color: #FFFFFF;
  background: #2684FF;
  border-radius: 20px;
  border: none;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;

  span {
    display: block;
  }

  @media (min-width: 768px) {
    height: 80px;
    font-size: 24px;
    gap: 28px;
    margin-top: 60px;
  }

  .btnIcon {
    @media (max-width: 759px) {
      width: 30px;
    }
  }
}

.blackBlock {
  background: #1A1B21;
  border-radius: 44px;

  @media (min-width: 768px) {
    border-radius: 70px;
  }

  .platformBlock {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 1201px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .platformElem {
    background: #24252B;
    border-radius: 20px;
    padding: 30px 40px 30px 15px;

    @media (min-width: 960px) {
      padding: 50px 73px 50px 30px;
    }

    .titleLine {
      display: flex;
      gap: 25px;
      align-items: center;

      .icon {
        width: 29px;
        height: 29px;

        @media (min-width: 768px) {
          width: 48px;
          height: 48px;
        }
      }
    }

    .platformElemDesc {
      color: #A6A6A6;
      font-size: 14px;
      line-height: 130%;
      text-align: left;
      margin-top: 22px;

      @media (min-width: 768px) {
        line-height: 120%;
        font-size: 18px;
        margin: 32px 0 0 74px;
      }
    }
  }
}

.advantagesBlock {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  .advantagesElem {
    background: #F1F1F1;
    border-radius: 16px;
    padding: 24px 16px;

    @media (min-width: 768px) {
      border-radius: 30px;
      padding: 50px;
    }

    .advantagesElemTitle {
      color: #0049FF;

      @media (max-width: 767px) {
        max-width: 420px;
        font-size: 16px;
      }
    }

    .advantagesElemDesc {
      color: #232323;
      font-size: 12px;
      line-height: 120%;
      text-align: left;
      margin-top: 16px;
      margin-bottom: 0;
      padding-left: 20px;

      @media (min-width: 768px) {
        font-size: 18px;
        margin-top: 30px;
      }

      li {
        margin-top: 12px;

        @media (min-width: 768px) {
          margin-top: 15px;
        }
      }
    }
  }
}

.lifeCycleBlock {
  padding-bottom: unset;

  .lifeCycleBlockTitle {
    max-width: 300px;

    @media (min-width: 768px) {
      max-width: 700px;
    }
  }
}

.greyBlock {
  background: #F1F1F1;
  margin: auto;
  padding: 40px 20px;
  border-radius: 44px;

  @media (min-width: 768px) {
    border-radius: 70px;
  }

  @media (min-width: 768px) {
    padding: 60px 40px 80px;
  }

  @media (min-width: 960px) {
    padding: 60px 40px 80px;
  }

  @media (min-width: 1600px) {
    padding: 60px 125px 80px;
  }

  @media (min-width: 1440px) {
    width: fit-content;
  }

  .lifeCycleContent {
    padding: unset;
    gap: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 1380px) {
      gap: 20px;
    }

    @media (min-width: 1440px) {
      width: 1300px;
    }

    .lifeCycleTitle {
      text-align: center;
      margin-bottom: 20px;
    }

    .lifeCycleElem {
      background: #FFFFFF;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      width: calc(100% - 40px);
      height: auto;

      @media (min-width: 380px) {
        padding: 30px;
        width: 300px;
      }

      @media (min-width: 768px) {
        padding: 30px;
        width: 360px;
      }

      @media (min-width: 960px) {
        height: 385px;
      }

      .lifeCycleItem {
        border-radius: 10px;
        padding: 20px 6px 20px;

        @media (min-width: 768px) {
          padding: 25px 6px 22px;
        }

        .lifeCycleSubTitle {
          text-align: center;
          color: #1A1B21;
          font-size: 14px;
          font-weight: 500;
          line-height: 120%;
          margin-top: unset;
          margin-bottom: 15px;

          @media (min-width: 768px) {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }

        .icons {
          display: flex;
          margin: auto;

          @media (max-width: 767px) {
            width: 250px;
          }
        }
      }
    }

    .thirdLifeCycleElem {
      @media (max-width: 1379px) {
        height: auto !important;
      }
    }

    .arrow {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 9px 0;

      @media (max-width: 767px) {
        height: 24px;
      }
    }

    .lifeCycleSmallElem {
      border-radius: 20px;
      padding: 12px 6px;
      display: flex;
      justify-content: space-around;

      @media (min-width: 768px) {
        padding: 20px 10px;
      }

      .lifeCycleSmallElemText {
        color: #1A1B21;
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
        margin-top: unset;
        margin-bottom: unset;

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}

.lilac {
  background-color: rgba(125, 162, 255, 0.5);
}

.green {
  background-color: rgba(177, 241, 38, 0.5);
}

.orange {
  background-color: rgba(255, 234, 146, 1);
}

.footerBlock {
  position: relative;

  .formBlock {
    background: #2684FF;
    border-radius: 44px;

    @media (min-width: 768px) {
      border-radius: 70px;
    }

    .formContent {
      overflow: hidden;

      @media (min-width: 960px) {
        padding-bottom: 60px;
      }

      @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
        overflow: unset;
      }

      .successResponse {
        background: #2684FF;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
        margin: 0;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 102%;
        height: 105%;
        align-items: center;
        display: none;
        padding: 5px 0 0 5px;

        @media (min-width: 1200px) {
          justify-content: center;
        }

        @media (min-width: 768px) {
          font-size: 24px;
        }

        @media (max-width: 767px) {
          justify-content: center;
        }
      }

      .failedResponse {
        color: #e1bb0b;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 16px;
        display: none;

        @media (min-width: 768px) {
          text-align: right;
        }
      }

      .formDescription {
        width: 100%;

        @media (min-width: 960px) {
          width: 800px;
        }

        @media (min-width: 1200px) {
          width: 35%;
        }

        .formTitle {
          color: #1A1B21;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          margin-bottom: 16px;

          @media (min-width: 768px) {
            font-size: 26px;
          }

          @media (min-width: 1200px) {
            margin-top: 10px;
            margin-bottom: 32px;
          }
        }

        .formSubTitle {
          color: #1D1D44;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          margin-bottom: 30px;

          @media (min-width: 768px) {
            font-size: 16px;
          }

          @media (min-width: 1200px) {
            margin-bottom: unset;
          }

          span {
            @media (min-width: 1200px) {
              display: block;
              width: 375px;
            }
          }
        }
      }

      .formInputs {
        position: relative;
        width: 100%;

        @media (min-width: 960px) {
          width: 800px;
        }

        @media (min-width: 1200px) {
          width: 58%;
        }

        .input {
          padding: 12px 10px;
          height: 47px;
          border-radius: 10px;
          border: none;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 120%;
          font-family: 'Inter', sans-serif;

          @media (min-width: 768px) {
            height: 57px;
          }
        }

        .smallInput {
          width: 100%;
        }

        .bigInput {
          width: 100%;
          margin-bottom: 16px;

          @media (min-width: 768px) {
            margin-bottom: 20px;
          }
        }

        .doubleInputs {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 16px;

          @media (min-width: 768px) {
            flex-direction: row;
            gap: 20px;
            margin-bottom: 20px;
          }
        }

        .btnContainer {
          @media (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          @media (hover: hover) {
            button:hover {
              background: #0d0d10 !important;
            }
          }

          .btnText {
            font-size: 12px;
            line-height: 120%;
            color: #24252B;
            margin-bottom: 16px;

            @media (min-width: 768px) {
              font-size: 14px;
              margin-bottom: unset;
            }

            @media (min-width: 1200px) {
              width: 390px;
            }

            @media (hover: hover) {
              a:hover {
                color: #FFFFFF !important;
                border-bottom: 1px solid #FFFFFF;
              }
            }

            .underlineText {
              border-bottom: 1px solid #24252B;
              cursor: pointer;
              transition: color 200ms ease,
              border-bottom-color 200ms ease;
            }
          }

          .blackBtn {
            background-color: #24252B;
            padding: 0 25px;
            width: 100%;

            @media (min-width: 768px) {
              min-width: 215px;
              width: unset;
              height: 62px;
            }
          }
        }
      }
    }
  }

  .blackBg {
    background-color: #24252B;
    position: absolute;
    width: 100%;
    top: 66%;
    height: 300px;
    z-index: -1;
  }
}

.footer {
  background-color: #24252B;

  .footerContent {
    position: relative;
    padding-top: 25px;
    padding-bottom: 65px;
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    @media (min-width: 768px) {
      gap: 30px;
      padding-top: 40px;
      padding-bottom: 90px;
    }

    @media (min-width: 960px) {
      padding-top: 81px;
      padding-bottom: 120px;
    }

    @media (min-width: 1200px) {
      padding-bottom: 153px;
    }

    .menu {
      button {
        background-color: unset;
        border: unset;
        margin: unset;
        padding: unset;
      }
    }

    .documents {
      font-size: 12px;
      line-height: 120%;

      @media (min-width: 1200px) {
        font-size: 14px;
      }

      a {
        cursor: pointer;
        display: block;
        width: fit-content;
        color: #FFFFFF;

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      a:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .footerText {
      .whiteLogo {
        width: 110px;
        margin-bottom: 18px;

        @media (min-width: 1200px) {
          width: 143px;
          height: 38px;
          margin-bottom: 23px;
        }
      }
    }

    .footerSubTitle {
      color: #9C9D9F;
      font-size: 14px;
      line-height: 120%;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin-bottom: 20px;
      }

      @media (min-width: 1200px) {
        font-size: 20px;
      }
    }

    .footerItem {
      display: block;
      color: #FFFFFF;
      line-height: 120%;
      cursor: pointer;
      gap: 10px;
      align-items: center;
      font-size: 12px;
      margin-bottom: 12px !important;
      transition: color 200ms ease;
      text-align: left;
      width: fit-content;

      @media (min-width: 768px) {
        margin-bottom: 14px !important;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        path {
          transition: fill 200ms ease;
        }
      }

      .footerIcon {
        width: 16px;
        height: 16px;

        @media (min-width: 1200px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    .flex {
      display: flex;
    }

    .price {
      box-sizing: border-box;
      border: 1px solid #9C9D9F;
      padding: 10px 8px 10px 12px;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 135%;
      height: fit-content;
      border-radius: 12px;
      max-width: 380px;

      @media (min-width: 768px) {
        max-width: 130px;
        padding: 15px 8px 15px 15px;
        border-radius: 30px;
      }

      @media (min-width: 960px) {
        max-width: 170px;
      }

      @media (min-width: 1200px) {
        max-width: unset;
        width: 374px;
        font-size: 16px;
        padding: 20px;
        line-height: 120%;
      }

      a {
        display: inline;
      }
    }

    .copyright {
      position: absolute;
      bottom: 25px;
      display: flex;

      @media (min-width: 768px) {
        bottom: 36px;
      }

      @media (min-width: 960px) {
        bottom: 49px;
      }

      div {
        display: flex;
        align-items: center;
        color: #9C9D9F;
        font-size: 12px;

        @media (min-width: 1200px) {
          font-size: 14px;
        }

        span {
          margin: 0 9px;
        }
      }
    }
  }
}

.lifeCycle {
  @media (min-width: 768px) {
    display: block;
    max-width: 134px;
  }
}

.descParam {
  @media (min-width: 768px) {
    display: block;
    max-width: 220px;
  }
}

.visibleResponse {
  display: flex !important;

  &.failedResponse {
    display: block !important;
  }
}
